import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Button } from "../../Core";
import { navigate } from "gatsby";

const HeroCareers = (props) => {
    return (
        <div className="dark-bg">
            <Container>
                <Row>
                    <Col sm={12} className="mb-80 mt-80 text-center">
                        <h1>We want you!</h1>
                        <br />
                        <Button onClick={() => navigate('/careers/#jobs')} className="medium-button blue-bg mt-5">View jobs</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default HeroCareers;
