import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Content from "../../sections/careers/Content"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../../utils/helperFn";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Text } from "../../components/Core";
import Seo from "../../components/Seo/Seo";
import { Button } from "../../../src/components/Core";
import HeroCareers from "../../components/Rebrand/Hero/HeroCareers";
import group_image from './../../assets/image/rebrand/jpg/group_image.jpg';
import ryan from './../../assets/image/rebrand/jpg/ryan.jpg';
import { navigate } from "gatsby";
import FreshStreamWidget from "./FreshStreamWidget";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import FeatureCard from "../../sections/FeatureGrid/shared/FeatureCard";
import { GetFeatureGrids } from "../../utils/helperFn";
import YouFibreIcons from "../../components/YouFibreIcons/YouFibreIcons";
import greenLine from "../../assets/image/rebrand/jpg/green-line.jpg";
import TiltDivider from "../../sections/TiltDivider";
import divider from "../../assets/image/rebrand/png/we-want-you.png";
import greenArrow from "../../assets/image/rebrand/png/green-arrow.png";

const GreenBorder = styled.div`
    border-bottom: 2px solid #08DE9E;
    width: 100%;
    height: 2px;
    margin-top: 20px;
    display: none;
    width: 90%;
    margin: 20px auto 0 auto;
    @media (max-width: 991px) {
        display: block;
    }
`

const Careers = () => {
    const [pageData, setPageData] = useState(null);
    const [youtubeUrl, setYoutubeUrl] = useState(null);
    const [careersData, setCareersData] = useState(null);
    const [benefitsData, setBenefitsData] = useState(null);

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        768: { items: 2 },
        1024: { items: 3 },
    };
    const responsive1 = {
        0: { items: 1 }
    };

    const dataQuery = useStaticQuery(graphql`
        query CareersSectionQuery {
            pageData: strapiCareersSection {
                section_title
                section_subtitle
                section_text_1
                job_section_title
                job_section_jobs_available
                job_section_jobs_unavailable
                section_2_title
                section_2_text
                section_3_title
                section_4_title
                section_4_text
                youtube
                autoplay
                quote_text
                quote_name
                quote_position
            }
            careers: allStrapiFeatureGrids(filter: {page: {eq: "careers"}}) {
                edges {
                    node {
                        id
                        title
                        page
                        grid_features {
                            icon
                            description
                            title
                        }
                    }
                }
            }
            benefits: allStrapiFeatureGrids(filter: {page: {eq: "benefits"}}) {
                edges {
                    node {
                        id
                        title
                        page
                        grid_features {
                            icon
                            description
                            title
                        }
                    }
                }
            }
        }
    `);

    useEffect(() => {
        setPageData(getProperty(dataQuery, 'pageData'));
        setCareersData(getProperty(dataQuery,'careers')); 
        setBenefitsData(getProperty(dataQuery,'benefits')); 
    }, []);

    useEffect(() => {
        pageData && console.log("pageData ====> ", pageData);
        setYoutubeUrl(`https://www.youtube.com/embed/${pageData?.youtube}?autoplay=${pageData?.autoplay}&amp;playsinline=1&amp;start=1&amp;enablejsapi=1&amp;controls=0&amp;fs=0&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;disablekb=1&amp;playlist=${pageData?.youtube}&amp;widgetid=1`);
    }, [pageData]);

    useEffect(() => {
        console.log("careersData ====> ", careersData?.edges[0]?.node);
    }, [careersData]);

    useEffect(() => {
        console.log("benefitsData ====> ", benefitsData?.edges[0]?.node);
    }, [benefitsData]);

    return (
        <div className="no-padding-wrapper overflow-x-hidden">
            <Seo page="careers" />
            <HeroCareers />
            <div className="careers pt-5">
                <Container>
                    <Row>
                        <Col md={12} lg={7}>
                            <h2>{pageData?.section_subtitle}</h2>
                            <p>{pageData?.section_text_1}</p>
                            <Button onClick={() => navigate('/careers/#jobs')} className="d-none d-lg-block blue-bg medium-button mt-2 mb-5">View jobs</Button>
                            <div className="d-block d-lg-none text-center">
                                <Button onClick={() => navigate('/careers/#jobs')} className="blue-bg medium-button mt-5 mb-5">View jobs</Button>
                            </div>
                        </Col>
                        <Col md={12} lg={5}>
                            <img src={group_image} className="w-100 removeHorizontalPadding" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="light-green-bg">
                <Container>
                    <Row>
                        <Col md={12} className="text-center pt-5">
                            <h2>{pageData?.section_2_title}</h2>
                            <p className="pl-5 pr-5">{pageData?.section_2_text}</p>
                        </Col>
                        <Col md={12} className="text-center pt-5 pb-sm-0 pb-md-5">
                            <iframe className="ytPlayer removeHorizontalPadding" frameborder="0" allowfullscreen="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="YouFibre" src={youtubeUrl}></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            { careersData && <div className="text-center green-bg pt-5 pb-5">
                <Container>
                    <Row>
                        <Col md={12} className="pb-5">
                            <h2>{pageData?.section_3_title}</h2>
                        </Col>
                        <Col xs={12} className="company-values center-items">
                            <AliceCarousel
                                mouseTracking
                                responsive={responsive}
                                disableButtonsControls={true}
                                infinite={true}
                                controlsStrategy="alternate">
                                    { careersData?.edges[0]?.node.grid_features?.map((item, index) => 
                                        <>
                                            <div  className="center-items pb-3">
                                                <img src={YouFibreIcons(item.icon)} className="big-icon mb-3" alt="YouFibre" />
                                                <h4 dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                                                <p className="px-3">{item.description}</p>
                                            </div>
                                        </>
                                    )}
                                </AliceCarousel>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
            <div className="testimonial">
                <Row>
                    <Col lg={5} xl={4} className="image grey-bg">
                        <img src={ryan} className="w-100"/>
                        <div className="quote right-quote"></div>
                    </Col>
                    <Col lg={6} xl={7} className="center-items offset-xl-1 offset-lg-0">
                        <Row>
                            <Col lg={12} xl={8} className="mobile-margin-top">
                                <p style={{ fontSize: '24px' }}>{pageData?.quote_text}</p>
                                <p style={{ fontSize: '24px' }}><b>{pageData?.quote_name}</b><br /><small>{pageData?.quote_position}</small></p>
                                <div className="quote left-quote"></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <Section className="dark-bg mb-30 pb-100">
                <Container>
                    <Row>
                        <Col sm={12} className="pb-5">
                            <h2 className="white-color text-center">
                                How we hire<img src={greenArrow} alt="" className="ml-3"/>
                            </h2>
                            <p className="white-color text-center">
                                Not knowing what is around the corner in an interview process can be scary. We are completely
                                transparent and want you to succeed. After all, we are looking for great people to support us on our journey.
                            </p>
                            <p className="white-color text-center">
                                Here's everything you need to know about how we hire, so you'll be set for success:
                            </p>
                        </Col>
                    </Row>
                    <div className="d-none d-lg-block">
                        <Row className="vertical-center">
                            <Col lg={4} className="flex green-bordered">
                                <img src={YouFibreIcons('youfibre-icon-meet')} className="big-icon mr-5" alt="YouFibre" />
                                <h2 className="white-color">Stage 1</h2>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block">
                                <img src={greenLine} width={4} />
                            </Col>
                            <GreenBorder />
                            <Col lg={7}>
                                <p className="white-color"><b>Let’s get acquainted!</b></p>
                                <p className="white-color">
                                    Your first call is with one of our dedicated recruiters.
                                    We’ll discuss the role, your background, and what
                                    it’s like to work at YouFibre. Be ready to chat about
                                    your experience and ask questions—curiosity is encouraged!
                                </p>
                            </Col>
                        </Row>
                        <Row className="vertical-center">
                            <Col lg={4} className="flex green-bordered">
                                <img src={YouFibreIcons('youfibre-icon-sign')} className="big-icon mr-5" alt="YouFibre" />
                                <h2 className="white-color">Stage 2</h2>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block">
                                <img src={greenLine} width={4} />
                            </Col>
                            <GreenBorder />
                            <Col lg={7}>
                                <p className="white-color"><b>The experience you’ll bring to YouFibre</b></p>
                                <p className="white-color">
                                    Meet your potential manager to dive deeper into your experience
                                    and how it aligns with our team’s needs. You’ll discuss specific
                                    projects and might meet future colleagues. This stage usually
                                    concludes the process, but some roles may have a third step.
                                </p>
                            </Col>
                        </Row>
                        <Row className="vertical-center">
                            <Col lg={4} className="flex green-bordered">
                                <img src={YouFibreIcons('youfibre-icon-write')} className="big-icon mr-5" alt="YouFibre" />
                                <h2 className="white-color">Stage 3</h2>
                            </Col>
                            <Col lg={1} className="d-none d-lg-block">
                                <img src={greenLine} width={4} />
                            </Col>
                            <GreenBorder />
                            <Col lg={7}>
                                <p className="white-color"><b>The Final Evaluation</b></p>
                                <p className="white-color">
                                    If needed, you’ll have a relaxed conversation with our leadership team.
                                    It’s a last chance for both sides to ask any remaining questions
                                    before a decision is made.
                                </p>
                            </Col>
                        </Row>
                    </div>

                    <div className="d-md-block d-lg-none">
                        <AliceCarousel
                            mouseTracking
                            responsive={responsive1}
                            disableButtonsControls={true}
                            infinite={true}
                            controlsStrategy="alternate"
                        >
                            <Row className="vertical-center">
                                <Col lg={4} className="flex green-bordered">
                                    <img src={YouFibreIcons('youfibre-icon-meet')} className="big-icon mr-5" alt="YouFibre" />
                                    <h2 className="white-color">Stage 1</h2>
                                </Col>
                                <Col lg={1} className="d-none d-lg-block">
                                    <img src={greenLine} width={4} />
                                </Col>
                                <GreenBorder />
                                <Col lg={7}>
                                    <p className="white-color">Let’s get acquainted!</p>
                                    <p className="white-color">
                                        Your first call with one of our dedicated recruiters.
                                        We’ll discuss the role, your background, and what it’s like to
                                        work at YouFibre. Be ready to chat about your experience and
                                        ask questions—curiosity is encouraged!
                                    </p>
                                </Col>
                            </Row>
                            <Row className="vertical-center">
                                <Col lg={4} className="flex green-bordered">
                                    <img src={YouFibreIcons('youfibre-icon-sign')} className="big-icon mr-5" alt="YouFibre" />
                                    <h2 className="white-color">Stage 2</h2>
                                </Col>
                                <Col lg={1} className="d-none d-lg-block">
                                    <img src={greenLine} width={4} />
                                </Col>
                                <GreenBorder />
                                <Col lg={7}>
                                    <p className="white-color">The experience you’ll bring to YouFibre</p>
                                    <p className="white-color">
                                        Meet your potential manager to dive deeper into your experience
                                        and how it aligns with our team’s needs. You’ll discuss specific
                                        projects and might meet future colleagues. This stage usually
                                        concludes the process, but some roles may have a third step.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="vertical-center">
                                <Col lg={4} className="flex green-bordered">
                                    <img src={YouFibreIcons('youfibre-icon-write')} className="big-icon mr-5" alt="YouFibre" />
                                    <h2 className="white-color">Stage 3</h2>
                                </Col>
                                <Col lg={1} className="d-none d-lg-block">
                                    <img src={greenLine} width={4} />
                                </Col>
                                <GreenBorder />
                                <Col lg={7}>
                                    <p className="white-color">The Final Evaluation</p>
                                    <p className="white-color">
                                        If needed, you’ll have a relaxed conversation with our leadership team.
                                        It’s a last chance for both sides to ask any remaining questions
                                        before a decision is made.
                                    </p>
                                </Col>
                            </Row>    
                        </AliceCarousel>
                    </div>
                    
                    <Row>
                        <Col sm={12}>
                            <small className="white-color text-center mt-5 centered d-block">
                                We are committed to fostering a workplace culture where equality, diversity,
                                and inclusion (EDI) are core values. We believe that a diverse team—rich in background,
                                experience, and perspectives—leads to stronger ideas and innovation. We welcome individuals of
                                all ages, genders, races, ethnicities, sexual orientations, abilities, and experiences to apply.<br />
                                If you require any reasonable adjustments for your interview, please contact us at <a className="white-color" href="mailto:recruitment@substantial.group">recruitment@substantial.group</a>.
                            </small>
                        </Col>
                    </Row>
                </Container>
            </Section>

            <TiltDivider
                img={divider}
                rotate={2}
            />

            { benefitsData && <div className="text-center green-bg pt-5 pb-5 d-md-block d-lg-none">
                <Container>
                    <Row>
                        <Col md={12} className="pb-5 mt-5">
                            <h2>{pageData?.section_4_title}</h2>
                            <p>{pageData?.section_4_text}</p>
                        </Col>
                        <AliceCarousel
                            mouseTracking
                            responsive={responsive1}
                            disableButtonsControls={true}
                            infinite={true}
                            controlsStrategy="alternate">
                                {benefitsData?.edges[0]?.node.grid_features?.map((item, index) => 
                                    <div className="pb-3 text-center">
                                        <img src={YouFibreIcons(item.icon)} className="big-icon" alt="YouFibre" />
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                    </div>
                                )}
                            </AliceCarousel>
                    </Row>
                </Container>
            </div>
            }

            { benefitsData && <div className="text-center green-bg pt-5 pb-5 d-none d-lg-block">
                <Container>
                    <Row>
                        <Col md={12} className="pb-5 mt-5">
                            <h2>{pageData?.section_4_title}</h2>
                            <p>{pageData?.section_4_text}</p>
                        </Col>
                        <Row>
                            {benefitsData?.edges[0]?.node.grid_features?.map((item, index) => 
                                <Col sm={4}>
                                    <div className="align-top pb-3">
                                        <img src={YouFibreIcons(item.icon)} className="big-icon" alt="YouFibre" />
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Row>
                </Container>
            </div>
            }

            <Section>
                <Container id="jobs">
                    <Row>
                        <Col md={12} className="text-center pt-3">
                            <h2>{pageData?.job_section_title}</h2>
                            <FreshStreamWidget />
                        </Col>
                    </Row>
                </Container>
            </Section>
        </div>
    )
}
export default Careers