import React, { FC } from "react";
import styled from 'styled-components';

interface Props {
    rotate?: number;
    img?: string;
    borderColor?: string;
}

const TiltDivider: FC<Props> = (props: Props) => {

    const { rotate = '-2', img, borderColor } = props;

    const Divider = styled.div`
        @keyframes scroll {
            to {
                background-position: -2000px 0;
            }
        }
        transform: rotate(${rotate}deg);
        position: relative;
        top: 38px;
        left: -5px;
        max-width: none;
        margin-top: -110px;
        width: 1920px;
        height: 90px;
        background: ${borderColor ? borderColor : "#09141e"} url(${img}) repeat-x;
        animation: scroll 30s linear infinite;
        background-size: 100% 100%;

    @media (min-width: 1921px) {
        width: 2560px;
        height: 114px;
        top: 40px;
        margin-top: -110px;
    }
    @media (min-width: 1510px) {
        width: 1248;
        height: 70;
        top: 40px;
        margin-top: -110px;
    }
    @media (max-width: 1024px) {
        left: -495px;
        width: 1600px;
    }
`;

    return (
        <Divider />
    )
}

export default TiltDivider;
